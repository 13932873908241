/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        $('body').fadeIn(1000, 'swing');
        // $(window).on('load', function(){
          setTimeout(function(){
              //bodyに付けているfadeのクラスを取る
              $('body').removeClass('fade-all');
          }, 400);
        // });
        //ページ内リンク、target属性がない場合のaタグが押された時
        $('a:not([href^="#"]):not([target])').on('click', function(e){
            e.preventDefault();
            link = $(this).attr('href');
            if (link !== '') {
                //bodyにフェードアウトさせるためのクラスを付与
                $('body').addClass('fadeout');
                setTimeout(function(){
                    window.location = link;
                }, 400);
            }
        return false;
        });
        $('button.menu-button').on('click', function (event) {
          $('html').toggleClass('menu-open');
        });
        $('.archive-links > li > a:not(.all), .archive-links-sp > li > a:not(.all)').on('click', function(event) {
          event.preventDefault();
        });
        $('.archive-links > li > a:not(.all), .archive-links-sp > li > a:not(.all)').parent().children('ul').slideToggle(0);
        var prevPos = 0;
        var naviTop = 235;
        var naviHeight = 0;
        function scrollEventsPageNavi() {
          var scroll = $(window).scrollTop();
          var scrollDiv = $('body > div').scrollTop();
          var winHeight = $(window).height();
          var bodyHeight = $('body').height();

          // if (scroll + 70 >= $('.page-navi').offset().top + $('.page-navi > h1').outerHeight(true)) {
          //   $('.wrap.container').addClass('menu-fixed');
          // } else {
          //   $('.wrap.container').removeClass('menu-fixed');
          // }

          // setTimeout(function() {
          //   if (scroll + winHeight > naviTop + naviHeight && $(window).width() > 767) {

          //     var offset;
          //     if (winHeight < naviTop + naviHeight) {
          //       offset = scroll - ((naviTop + naviHeight) - winHeight);
          //     } else {
          //       offset = scroll;
          //     }
          //     if (scroll > $('section.foot').offset().top - winHeight) {
          //       //$('.page-navi').css({'bottom': 130});
          //     } else {
          //       $('.page-navi').css({'top': offset});
          //     }
          //   } else {
          //     $('.page-navi').css({'top': 0});
          //   }

          // }, 200);

          if (winHeight < bodyHeight) {
            // if (scroll > 10 && scroll <= $('section.foot').offset().top - winHeight) {
            if (scroll > 10) {
                $('a.to-top-button').show();
              // $('a.to-top-button').addClass('fixed');
            } else {
              $('a.to-top-button').hide();
              // $('a.to-top-button').removeClass('fixed');
            }
          } else {
            $('a.to-top-button').hide();
          }
          prevPos = scroll;
        }

        // if ($('body.page .page-navi, body.archive .page-navi, body.search .page-navi, body.single-news .page-navi').size()) {
          prevPos = 0;
          naviTop = 235;
          naviHeight = $('.page-navi > h1').outerHeight(true) + $('.page-navi > ul').outerHeight(true);
          $(window).on('load resize', function () {
            $('main.main').addClass('show');
            naviHeight = $('.page-navi > h1').outerHeight(true) + $('.page-navi > ul').outerHeight(true);
          });

          $(window).on('load scroll', scrollEventsPageNavi);
          // $(window).on('load', scrollEventsPageNavi);
          // $('body').on('touchmove', scrollEventsPageNavi);
          $('a.to-top-button').on('click', function (event) {
            event.preventDefault();
            $('html').velocity('scroll', {duration: 600});
          });
        // } else if($('body.page')) {
          $(window).on('load resize', function () {
            $('main.main').addClass('show');
          });
        // }

        $('.category-button').on('click', function() {
          $(this).toggleClass('open');
        });

        $('.menu-select').on('change', function() {
          location.href = $(this).val();
        });

        objectFitImages();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        var mySwiper = new Swiper ('.swiper-container-slide', {
          autoplay: {
            delay: 5000,
          },
          navigation: {
            nextEl: '.swiper-button-next-109',
            prevEl: '.swiper-button-prev-109',
          },
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

        // JavaScript to be fired on the home page
        // $('.link-btn > a').html('NEWS一覧');
        // $(window).on('load resize', function () {
        //   $('.main-visual').height($(window).height() - $('.copy-shine').height());
        // });
        // $(window).on('load', function () {
        //   $('.main-visual').addClass('fit');
        //   $('.main-visual .image').velocity({'top': 0, 'left': 0, width: '100%', height: '100%'}, {duration: 2000, delay: 500, easing: 'ease'});
        //   $('.main-visual .copy').velocity('fadeIn', {duration: 1000, delay: 2500});
          // $('.main-visual .scroll-button').velocity('fadeIn', {duration: 1000, delay: 3600});
        // });

        // function scrollEvents() {
        //   var scroll = $(window).scrollTop();
        //   var winHeight = $(window).height();
        //   if (scroll >= winHeight / 2) {
        //     $("header.banner").addClass('show');
        //   } else {
        //     $("header.banner").removeClass('show');
        //   }
        //   if (scroll < winHeight) {
        //     $('.main-visual .image').css({'top': scroll/3});
        //   }
        //   if (scroll > $('section.service').offset().top - (winHeight * 2 / 3)) {
        //     $("section.service .left, section.service .right").addClass('show');
        //   } else {
        //     $("section.service .left, section.service .right").removeClass('show');
        //   }
        // }

        // $(window).on('load scroll', scrollEvents);
        // $('body').on('touchmove', scrollEvents);

        // $('.main-visual .scroll-button').on('click', function (event) {
        //   event.preventDefault();
        //   $('section.news').velocity('scroll', {duration: 1000, offset: -1 * $('header.banner').height()});
        // });

        var wpcf7Elm = document.querySelector( '.wpcf7' );
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
          location.href = 'https://www.shibuya109.co.jp/thanks'
        }, false );

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'company_5': {
      init: function() {
        // JavaScript to be fired on the about us page
        var customMapType = new google.maps.StyledMapType([
            { stylers: [
                {hue: '#890000'},
                {visibility: 'simplified'},
                {gamma: 0.5},
                {weight: 0.5},
                {saturation:-100},
                {lightness: 20}
            ] }
        ], {
          name: 'Custom Style'
        });
        var customMapTypeId = 'custom_style';
        var myLatLng = new google.maps.LatLng(35.657206, 139.698905);
        var map = new google.maps.Map(document.getElementById('map'), {
                                center: myLatLng,
                                zoom: 17,
                                mapTypeControl: false,
                                mapTypeControlOptions: {
                                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
                                }
        });
        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: 'SdBB'
        });

        map.mapTypes.set(customMapTypeId, customMapType);
        map.setMapTypeId(customMapTypeId);
      }
    },
    'leasing': {
      init: function() {

      }
    },
    'service_promotion': {
      init: function () {
        var mySwiper = new Swiper('.swiper-container-slide', {
          speed: 800,
          autoplay: {
            delay: 5000,
          },
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
